import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyD0PVTyr9USiLfH0SHn9p29tYP8EwkKU6E",
  authDomain: "webrtc-704a6.firebaseapp.com",
  databaseURL: "https://webrtc-704a6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "webrtc-704a6",
  storageBucket: "webrtc-704a6.appspot.com",
  messagingSenderId: "608976290925",
  appId: "1:608976290925:web:a3efa8d151ead4f3772b64",
  measurementId: "G-FMQQ9EVL1G"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();

export const userName = prompt("What's your name?");
const urlparams = new URLSearchParams(window.location.search);
const roomId = urlparams.get("id");

if (roomId) {
  firepadRef = firepadRef.child(roomId);
} else {
  firepadRef = firepadRef.push();
  window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
}

export default firepadRef;
